@use "../../variables.scss";

.nf-page {
  &__container {
  }

  &__content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
  }

  &__gif {
    border-radius: 1000px;
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
  }
}
