@use "../../variables.scss";

.tooltip {
  transition: display 0s, opacity 0.3s linear;
  display: none;
  opacity: 0;
  position: absolute;
  width: 0;
}

.tooltip__background-active {
  height: 20px;
  background-color: variables.$color-tooltip-background;
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 1;
  position: absolute;
}

.tooltip__text {
  margin: 0 10px;
  font-family: variables.$font-heading;
  font-size: variables.$size-font-tooltip;
  color: variables.$color-tooltip-text;
}
