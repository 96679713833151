.account {
  &__bottom {
    margin-top: auto;
    width: 100%;
  
    & > button {
      margin: 10px 0 0 0
    }
  }

  &__button {
    margin-bottom: 10px;
  }
}