@use "../../variables.scss";

.multi-list {
  &__categories {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: thin;

    @media (min-width: variables.$size-mobile-max) {
      flex-wrap: wrap;
      overflow: hidden;
    }
    & > div + div {
      margin-left: 20px;
    }
  }

  &__category {
    background-color: white;
    min-width: fit-content;
    padding: 5px 10px;
    margin: 0 0 10px 0;
    
    border-radius: 20px;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;

    & > p {
      padding: 0;
      margin: 0;
    }

    &--selected {
      border: none;
      background-color: black;
      color: white;
    }
  }
}
