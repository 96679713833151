@use "../../variables.scss";

.register-page {
  &__container {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
  &__logo {
    margin-bottom: 50px;
    width: 150px;
  }

  &__phone {
    display: flex;
    justify-content: space-between;
  }

  &__links {
    display: flex;
    flex-direction: column;

    &__divider {
      align-self: center;
      width: 100%;
      max-width: 500px;
      margin: 50px 0;
      border: 2px blue black;
      border-radius: 20px;
    }
  
    &__login {
      text-align: center;
      align-self: center;
      font-family: variables.$font-content;
      font-size: 15px;
      cursor: pointer;
      user-select: none;
    }
  }
}

