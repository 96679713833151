@use "../../variables.scss";

.bottom-container {
  &__container {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 40px 20px 20px 20px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: variables.$size-mobile-max) {
      & > * {
        max-width: 1000px;
      }
    }
  }
}
