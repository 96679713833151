@use "../../variables.scss";

.page {
  &__loadingContainer {
    position: fixed;
    z-index: 5;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  &__container {
    margin-top: 50px;
    overflow: hidden;
    max-width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    transition: 0.3s;
    opacity: 1;

    &--loading {
      opacity: 0;
    }
  }

  &__header {
    background-color: variables.$color-header-background;
    left: 0;
    top: 0;
  }

  &__content {
    flex-direction: column;
    display: flex;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 80px 20px 20px 20px;
    align-self: center;

    &__children {
      margin-bottom: 100px;
      width: 100%;
    }

    @media (min-width: variables.$size-mobile-max) {
      width: variables.$size-mobile-max;
      align-self: center;
    }
  }

  &__title {
    font-size: variables.$size-font-title;
    color: black;
    margin: 0;
  }

  &__standard {
    position: absolute;
    z-index: 999;
    width: 100vw;
    height: 100vh;

    & > div {
      background-color: variables.$color-transition-background;
      position: absolute;
      min-width: 150vw;
      min-height: -webkit-fill-available;
      min-height: 150vh;
    }

    &__in {
      transform: translateX(-50vw);
    }

    &__out {
      transform: translateX(200vw);
      // transform: translate(100vw);
    }
  }
}

.transition {
  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: 200ms;
    }
  }

  &-exit {
    opacity: 1;
    &-active {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
}
